import { useTranslation } from 'react-i18next'
import useValidation from '@/utils/hooks/useValidation'
import { StoreAgreementValidation } from './rules'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  AgreementGroupEnum,
  useStoreAgreementMutation,
} from '@/features/agreements'
import Card from '@/features/components/cards'
import { FormControl } from '@mui/base'
import {
  FormHelperText,
  Label,
  TextInput,
} from '@/components'
import Select from 'react-select'
import { Checkbox } from '@/features/components/inputs/checkbox'
import { Button } from '@/features/components/buttons/button'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const AgreementCreatePage = () => {
  const { t } = useTranslation([
    'form',
    'validation',
    'utils',
    'agreements',
  ])
  const { schema, defaultValues } = useValidation(
    new StoreAgreementValidation(),
    t
  )
  const { handleSubmit, control } = useForm<
    typeof defaultValues
  >({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const [storeAgreement] =
    useStoreAgreementMutation()
  const navigate = useNavigate()

  const onSubmit = async (
    data: typeof defaultValues
  ) => {
    try {
      await storeAgreement(
        serialize(
          {
            ...data,
            groups: data.groups.map((group) => ({
              name: group,
            })),
          },
          {
            indices: true,
            booleansAsIntegers: true,
          }
        )
      ).unwrap()
      toast.success(
        t('agreements:create.success')
      )
      navigate('/agreements')
    } catch (error) {
      toast.error(
        t('utils:errors.something_went_wrong')
      )
    }
  }

  return (
    <div
      className={
        'flex flex-col gap-y-6 p-3 lg:p-6'
      }
    >
      <h5 className={'text-2xl font-bold'}>
        {t('agreements:create.title')}
      </h5>
      <Card margin>
        <form
          className={'flex flex-col gap-4'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className={
              'grid lg:grid-cols-4 gap-4'
            }
          >
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <TextInput
                    label={t('form:labels.name')}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'name'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  className={'lg:col-span-2'}
                  error={!!error}
                >
                  <TextInput
                    multiline
                    rows={5}
                    label={t(
                      'form:labels.description'
                    )}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'description'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  {...field}
                  error={!!error}
                >
                  <TextInput
                    label={t(
                      'form:labels.text_link'
                    )}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'text_link'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  className={'flex flex-col'}
                  error={!!error}
                >
                  <Label
                    label={t(
                      'form:labels.agreement_group'
                    )}
                  />
                  <Select
                    onChange={(value) =>
                      field.onChange(
                        value.map((v) => v.value)
                      )
                    }
                    value={field.value.map(
                      (v) => ({
                        value: v,
                        label: t(
                          `utils:agreement_groups.${v}`
                        ),
                      })
                    )}
                    options={Object.values(
                      AgreementGroupEnum
                    ).map((value) => ({
                      value,
                      label: t(
                        `utils:agreement_groups.${value}`
                      ),
                    }))}
                    isMulti
                    placeholder={t(
                      'form:placeholders.select_groups'
                    )}
                    classNames={{
                      control: () =>
                        '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent !min-h-[42px]',
                      menuList: () =>
                        '!max-h-[200px]',
                    }}
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'groups'}
              control={control}
            />
            <Controller
              render={({
                field,
                fieldState: { error },
              }) => (
                <FormControl
                  className={'flex flex-col'}
                  error={!!error}
                >
                  <Label
                    label={t('form:labels.file')}
                  />
                  <input
                    type={'file'}
                    accept={'application/pdf'}
                    onChange={(e) =>
                      e.target.files &&
                      field.onChange(
                        e.target.files[0]
                      )
                    }
                  />
                  <FormHelperText
                    message={error?.message}
                  />
                </FormControl>
              )}
              name={'file'}
              control={control}
            />
            <div
              className={
                'flex flex-col gap-1 pt-4'
              }
            >
              <Controller
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                    label={t(
                      'form:labels.required'
                    )}
                  />
                )}
                name={'required'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                    label={t(
                      'form:labels.featured'
                    )}
                  />
                )}
                name={'featured'}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                    label={t(
                      'form:labels.visible'
                    )}
                  />
                )}
                name={'visible'}
                control={control}
              />
            </div>
          </div>
          <div className={'flex pt-4'}>
            <Button
              variant={'contained'}
              type={'submit'}
            >
              {t('form:buttons.save')}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  )
}

export { AgreementCreatePage }
